import svg4everybody from 'svg4everybody';
import ScrollMagic from 'scrollmagic';
var controller = new ScrollMagic.Controller();
var n_duration           = 0;
var n_triggersize        = 10;
var scroll_magic_enabled = true;

if (!window.site) window.site = {};

// One time after page loading
window.site.initPage = function () {
};

// Every time after creating new elements. For example: after ajax loading
window.site.initElements = function () {
  svg4everybody();
};

window.addEventListener('load', () => {
  window.site.initPage();
  window.site.initElements();
});

if (scroll_magic_enabled){

  new ScrollMagic.Scene({
    triggerElement : "#header-transition",
    triggerHook    : n_triggersize, //show, when scrolled 10% into view
    duration       : n_duration, // hide 10% before exiting view (80% + 10% from bottom)
    offset         : 50 // move trigger to center of element
  })
  .setClassToggle("#fade-header", "visible") // add class to reveal
  .addTo(controller);

  new ScrollMagic.Scene({
    triggerElement : "#services-introduction-transition",
    triggerHook    : n_triggersize, //show, when scrolled 10% into view
    duration       : n_duration, // hide 10% before exiting view (80% + 10% from bottom)
    offset         : 50 // move trigger to center of element
  })
  .setClassToggle("#services-intro", "visible") // add class to reveal
  .addTo(controller);

  new ScrollMagic.Scene({
    triggerElement : "#services-lists-transition",
    triggerHook    : n_triggersize, //show, when scrolled 10% into view
    duration       : n_duration, // hide 10% before exiting view (80% + 10% from bottom)
    offset         : 50 // move trigger to center of element
  })
  .setClassToggle("#services-lists", "visible") // add class to reveal
  .addTo(controller);

}






  new ScrollMagic.Scene({
    triggerElement : "#partners",
    triggerHook    : n_triggersize, //show, when scrolled 10% into view
    duration       : n_duration, // hide 10% before exiting view (80% + 10% from bottom)
    offset         : 50 // move trigger to center of element
  })
  .setClassToggle("#partners", "visible") // add class to reveal
  .addTo(controller);

  new ScrollMagic.Scene({
    triggerElement : "#contact_us",
    triggerHook    : n_triggersize, //show, when scrolled 10% into view
    duration       : n_duration, // hide 10% before exiting view (80% + 10% from bottom)
    offset         : 50 // move trigger to center of element
  })
  .setClassToggle("#contact_us", "visible") // add class to reveal
  .addTo(controller);

  new ScrollMagic.Scene({
    triggerElement : "#footer",
    triggerHook    : n_triggersize, //show, when scrolled 10% into view
    duration       : n_duration, // hide 10% before exiting view (80% + 10% from bottom)
    offset         : 50 // move trigger to center of element
  })
  .setClassToggle("#footer", "visible") // add class to reveal
  .addTo(controller);

  